// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { collection, addDoc, doc, onSnapshot, query, where, orderBy, getDocs, Timestamp } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDhcm-eWNiv57Xvw8sMsE5uNXrmrYwdS_E",
  authDomain: "heliotherm-ab8a3.firebaseapp.com",
  projectId: "heliotherm-ab8a3",
  storageBucket: "heliotherm-ab8a3.appspot.com",
  messagingSenderId: "523046402118",
  appId: "1:523046402118:web:5b53868f8517c3a95e7247"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const logHelioThermData = (data) =>
     addDoc(collection(db, 'heating'), data);

export const getData = async (start, end) => {
  
  const data = [];

  const q = query(
    collection(db, 'heating'),
    where('time', '>=', Timestamp.fromDate(start)),
    where('time', '<=', Timestamp.fromDate(end)),
    orderBy('time'));
  
  const querySnapshot = await getDocs(q);
  
  querySnapshot.forEach((doc) => {
    const values = doc.data();
    values.timeString = values.time.toDate().toLocaleString();
    data.push(values);
  });
  
  return(data);
}
export const getMinus100 = async () => {
  
  const dates = [];

  const q = query(
    collection(db, 'heating'),
    where('time', '>=', Timestamp.fromDate(new Date('2022-02-01'))),
    where('outsideTemp', '==', -100),
    orderBy('time'));
  
  const querySnapshot = await getDocs(q);
  
  querySnapshot.forEach((doc) => {
    const values = doc.data();
    // values.timeString = values.time.toDate().toLocaleString();
    dates.push(values.time.toDate().toDateString());
  });
  
  return([...new Set(dates)]);
}