
import './App.css';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useEffect, useState } from 'react';
import { getData, getMinus100 } from './Firebase';

import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';

const graphType = 'monotone';

const App = () => {

  const [data, setData] = useState([]);
  const [minus100Dates, setMinus100Dates] = useState([]);

  const [dateRange, setDateRange] = useState([new Date (new Date() - (60 * 60 * 24 * 1000)), new Date()]); // last 24 hours

  const [showHeatingOutTemp, setShowHeatingOutTemp] = useState(true);
  const [showHeatingReturnTemp, setShowHeatingReturnTemp] = useState(false);
  const [showHeatingSetpoint, setShowHeatingSetpoint] = useState(false);
  const [showHotwaterSetpoint, setShowHotwaterSetpoint] = useState(false);
  const [showHotwaterTemp, setShowHotwaterTemp] = useState(true);
  const [showOutsideTemp, setShowOutsideTemp] = useState(false);
  const [showHeatpumpMode, setShowHeatpumpMode] = useState(true);
  const [showOperatingMode, setShowOperatingMode] = useState(true);

  useEffect(()=>{
    async function initData() {
      const data = await getData(dateRange[0], dateRange[1]);
      // console.log(data);
      setData(data);
    }
    initData();
    
  },[dateRange]);

  useEffect(()=>{
    async function minus100s() {
      const data = await getMinus100();
      // console.log(data);
      setMinus100Dates(data);
    }
    minus100s();
  },[]);


  const clickLegend = (event) => {
    switch(event.dataKey) {
      case 'heatingOutTemp':
        setShowHeatingOutTemp(!showHeatingOutTemp);
        break;

      case 'heatingReturnTemp':
        setShowHeatingReturnTemp(!showHeatingReturnTemp);
        break;

      case 'heatingSetpoint':
        setShowHeatingSetpoint(!showHeatingSetpoint);
        break;

      case 'hotwaterSetpoint':
        setShowHotwaterSetpoint(!showHotwaterSetpoint);
        break;

      case 'hotwaterTemp':
        setShowHotwaterTemp(!showHotwaterTemp);
        break;

      case 'outsideTemp':
        setShowOutsideTemp(!showOutsideTemp);
        break;

      case 'heatpumpRequestType':
        setShowHeatpumpMode(!showHeatpumpMode);
        break;

      case 'operatingMode':
        setShowOperatingMode(!showOperatingMode);
        break;

      default:
    }
  }

  return (
    <div className="App">
      <div className="Chart">
        <DateTimeRangePicker
          onChange={setDateRange}
          value={dateRange}
        />
      <ResponsiveContainer width="100%" height="100%">
          <LineChart
            // width={500}
            // height={300}
            data={data}
            // margin={{
              // top: 5,
              // right: 30,
              // left: 20,
              // bottom: 5,
            // }}
          >
            {/* <CartesianGrid  /> */}
            <XAxis dataKey="timeString" interval="preserveEnd" tickCount={1}/>
            <YAxis/>
            <Tooltip />
            <Legend onClick={clickLegend}/>
            <Line hide={!showHeatingOutTemp} dot={false} type={graphType} dataKey="heatingOutTemp" stroke="red" />
            <Line hide={!showHeatingReturnTemp} dot={false} type={graphType} dataKey="heatingReturnTemp" stroke="blue" />
            <Line hide={!showHeatingSetpoint} dot={false} type={graphType} dataKey="heatingSetpoint" stroke="cyan" />
            <Line hide={!showHotwaterSetpoint} dot={false} type={graphType} dataKey="hotwaterSetpoint" stroke="yellow" />
            <Line hide={!showHotwaterTemp} dot={false} type={graphType} dataKey="hotwaterTemp" stroke="green" />
            <Line hide={!showOutsideTemp} dot={false} type={graphType} dataKey="outsideTemp" stroke="magenta" />
            <Line hide={!showHeatpumpMode} dot={false} type={graphType} dataKey="heatpumpRequestType" stroke="orange" />
            <Line hide={!showOperatingMode} dot={false} type={graphType} dataKey="operatingMode" stroke="black" />
            
          </LineChart>
        </ResponsiveContainer>
        <p>Click legend items to show/hide</p>
        <p>Heat pump request values: 0 - No request 20 - Heating, 30 - Hot water</p>
        <p>Operating mode values: 0 - Off, 10 - Auto, 30 - Summer</p>
      </div>
      <div>
        <h3> Times when -100 temperature</h3>
        <ul>
          {minus100Dates.map((date, index) => 
            <li key={index}>
              {date}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default App;
